import React, {  useState } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import TeamBox from "./TeamBox";

const Clients = () => {
  const [state] = useState({
    teams: [
      {
        image: "assets/images/team/img-1.jpg",
        title: "Frank Johnson",
        desc: "CEO",
      },
      {
        image: "assets/images/team/img-2.jpg",
        title: "Elaine Stclair",
        desc: "DESIGNER",
      },
      {
        image: "assets/images/team/img-3.jpg",
        title: "Wanda Arthur",
        desc: "DEVELOPER",
      },
      {
        image: "assets/images/team/img-4.jpg",
        title: "Joshua Stemple",
        desc: "MANAGER",
      },
    ],
  });

  return (
    <React.Fragment>
      <section className="section pt-4" id="team">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Nuestro Equipo"
            desc="Detrás de cada servicio excepcional de ADN, hay un equipo extraordinario. Profesionales apasionados, expertos en su campo, comprometidos con la excelencia. Conózcanos y descubra el corazón humano de nuestra marca."
          />

          <Row className="mt-5">
            {/* team box */}
            <TeamBox teams={state.teams} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Clients;
